/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
    padding: 30px 0;
    height: 92px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;
}

#header.header-scrolled {
    background: rgba(0, 0, 0, 0.9);
    padding: 20px 0;
    height: 72px;
    transition: all 0.5s;
}

#header #logo {
    float: left;
}

@media (min-width: 1024px) {
    #header #logo {
        padding-left: 60px;
    }
}

#header #logo h1 {
    font-size: 34px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
}

#header #logo h1 a,
#header #logo h1 a:hover {
    color: #fff;
    padding-left: 10px;
    border-left: 4px solid #18d26e;
}

#header #logo img {
    padding: 0;
    margin: 0;
}

@media (max-width: 768px) {
    #header #logo h1 {
        font-size: 28px;
    }
    #header #logo img {
        max-height: 40px;
    }
}